import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { DataIngestionService } from 'src/app/core/services/dataIngestion.service';

/**
 * IngestionPanelComponent
 */
@Component({
    selector: 'app-new-ingestion-form',
    templateUrl: './new-ingestion-form.component.html',
    styleUrls: ['./new-ingestion-form.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class NewIngestionFormComponent implements OnInit {
    private ingestionModel: any;
    public selectedNodes: TreeNode[];

    templateGroups: any = [];
    completeTemplateList: any = [];
    templates: any = [];
    selectedTemplateGroupId: number;
    selectedTemplateId: number;
    selectedTemplate: any;
    showFileUploadSection: boolean = false;

    accountingFrameworks: any = [];
    reportingLevels: any = [];

    newReportPack: boolean = true;
    selectedReportPack: any;
    newReportPackSequence: boolean = false;
    selectedReportPackSequence: any;

    dataPrepInProgress: boolean = false;

    private ingestionFile: File | null;
    showFileDetail = false;

    public loading = false;
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();
    selectedReports: any;

    /**
     * Creates an instance of approver panel with the list of ingestions.
     */
    constructor(
        private alertService: AlertService,
        private router: Router,
        private authService: AuthenticationService,
        public dataIngestionService: DataIngestionService
    ) {
        this.ingestionFile = null;
    }

    /**
     * on init
     */
    ngOnInit() {

        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }
        if (!this.dataIngestionService.dataForStartIngestion) {
            this.loading = true;
            this.dataIngestionService.getStartIngestionData().then((data: any) => {
                this.prepTemplateList(data.IngestionTemplates);
                this.accountingFrameworks = JSON.parse(JSON.stringify(data.AccountingFrameworks));
                this.reportingLevels = JSON.parse(JSON.stringify(data.ReportingLevels));
                this.initializeIngestionModel();
                this.loading = false;
            }).catch(() => { this.loading = false; });
        }
        else {
            this.templateGroups = JSON.parse(JSON.stringify(this.dataIngestionService.ingestionTemplateGroups));
            this.completeTemplateList = JSON.parse(JSON.stringify(this.dataIngestionService.ingestionTemplates));
            this.accountingFrameworks = JSON.parse(JSON.stringify(this.dataIngestionService.dataForStartIngestion.AccountingFrameworks));
            this.reportingLevels = JSON.parse(JSON.stringify(this.dataIngestionService.dataForStartIngestion.ReportingLevels));
            this.initializeIngestionModel();
        }
    }

    initializeIngestionModel() {
        this.ingestionModel = {
            loadId: 0, currentStatus: 'Not Started', fileName: '', reportsToPrepare: '', reportpackid: 0, reportpacktitle: '',
            reportpackdesc: '', reportingperiod: null, changereason: null, tableId: null, correspondingReportId: null,
            correspondingReport: null, templateGroupDataTypes: [], accountingFrameworkId: this.accountingFrameworks[0].AccountingFrameworkId,
            reportingLevelId: this.reportingLevels[0].ReportingLevelId, reportPackSequenceId: 0, reportPackSequenceName: '',
            reportPackSequenceDescription: ''
        };
        this.selectedTemplateGroupId = this.templateGroups[0].value;
        this.onTemplateGroupChange();
    }

    prepTemplateList(data) {
        var templateList = JSON.parse(JSON.stringify(data));

        if (this.templateGroups.length == 0 && this.completeTemplateList.length == 0) {
            templateList.forEach(el => {
                if (this.templateGroups.filter(x => x.value == el.TemplateGroupId).length == 0) {
                    this.templateGroups.push({ value: el.TemplateGroupId, name: el.TemplateGroup });
                }

                this.completeTemplateList.push({
                    name: el.TemplateName, value: el.TemplateId, templateGroupId: el.TemplateGroupId, showFileUpload: el.ShowFileUpload, tableId: el.TableId,
                    reportDateMandatory: el.ReportDateMandatory, correspondingReportId: el.CorrespondingReportId, correspondingReport: el.CorrespondingReportName
                });
            });
            this.dataIngestionService.ingestionTemplateGroups = JSON.parse(JSON.stringify(this.templateGroups));
            this.dataIngestionService.ingestionTemplates = JSON.parse(JSON.stringify(this.completeTemplateList));
        }
    }

    onTemplateGroupChange() {
        this.templates = this.completeTemplateList.filter(x => x.templateGroupId == this.selectedTemplateGroupId);
        this.ingestionModel.templateGroupDataTypes = this.getTableIdsForTemplateGroup(this.selectedTemplateGroupId);
        this.selectedTemplateId = this.templates[0].value;
        this.onTemplateChange();
    }

    onTemplateChange() {
        this.selectedTemplate = this.templates.filter(x => x.value == this.selectedTemplateId)[0];
        this.showFileUploadSection = this.selectedTemplate.showFileUpload;
        this.ingestionModel.templateId = this.selectedTemplate.value;
        this.ingestionModel.tableId = this.selectedTemplate.tableId;
        this.ingestionModel.correspondingReportId = this.selectedTemplate.correspondingReportId;
        this.ingestionModel.correspondingReport = this.selectedTemplate.correspondingReport;
    }

    onNewReportPackSelectionChange() {
        this.selectedReportPack = null;
        this.onExistingReportPackSelection();
    }

    onRPSequenceSelectionChange() {
        this.selectedReportPackSequence = null;
        this.onExistingRPSequenceSelection();
    }

    handleFileInput(files: FileList) {
        if (files.item(0) !== null) {
            const splitFilename = files.item(0).name.split('.');
            const ext = splitFilename.length >= 2 ? splitFilename[splitFilename.length - 1] : null;
            if (ext && ext != 'csv') {
                this.alertService.error('The uploaded file is not valid. Please upload a csv file to continue!');
                return;
            }
            // else if (parseFloat(((files[0].size / 1024) / 1024).toFixed(2)) > 35) {
            //     this.alertService.error('Please upload a file of size upto 35 MB. For files with size greater than 35 MB, please follow the batch upload process.');
            //     return;
            // }
            else {
                this.ingestionFile = files.item(0);
                this.ingestionModel.fileName = files.item(0).name;
                this.showFileDetail = true;
            }
        }
    }

    onExistingReportPackSelection() {
        if (this.selectedReportPack) {
            this.ingestionModel.reportpackid = this.selectedReportPack.ReportPackID;
            this.ingestionModel.reportpacktitle = this.selectedReportPack.Title;
            this.ingestionModel.reportpackdesc = this.selectedReportPack.RP_Description;
            this.ingestionModel.reportingperiod = this.selectedReportPack.ReportDate ? this.selectedReportPack.ReportDate: null;
            this.ingestionModel.reportPackSequenceId = this.selectedReportPack.DefaultRPSequenceId ? this.selectedReportPack.DefaultRPSequenceId : 0;
            this.ingestionModel.rpHasNullReportingDate = this.selectedReportPack.ReportDate ? false : true;
            this.checkForActiveDataPreps(this.ingestionModel.reportpackid);
        }
        else {
            this.ingestionModel.reportpackid = 0;
            this.ingestionModel.reportpacktitle = '';
            this.ingestionModel.reportpackdesc = '';
            this.ingestionModel.reportingperiod = null;
            this.ingestionModel.reportPackSequenceId = 0;
            this.ingestionModel.rpHasNullReportingDate = false;
        }
    }

    onExistingRPSequenceSelection() {
        if (this.selectedReportPackSequence) {
            this.ingestionModel.reportPackSequenceId = this.selectedReportPackSequence.RPSId;
            this.ingestionModel.reportPackSequenceName = this.selectedReportPackSequence.RPSequenceName;
            this.ingestionModel.Description = this.selectedReportPackSequence.Description;
        }
        else {
            this.ingestionModel.reportPackSequenceId = 0;
            this.ingestionModel.reportPackSequenceName = '';
            this.ingestionModel.Description = '';
        }
    }

    checkForActiveDataPreps(reportPackId: number) {
        this.dataIngestionService.checkForActiveDataPreparations(reportPackId).then((hasActiveDataPreps: any) => {
            this.dataPrepInProgress = hasActiveDataPreps;
        });
    }

    async onStartIngestion() {
        if (this.newReportPack && (this.ingestionModel.reportpacktitle === null || this.ingestionModel.reportpacktitle === '')) {
            this.alertService.error('Report pack title is a mandatory field!');
            return;
        }
        if (!this.newReportPack && (this.ingestionModel.reportpackid === null || this.ingestionModel.reportpackid === undefined || this.ingestionModel.reportpackid == 0)) {
            this.alertService.error('Please select a report pack!');
            return;
        }
        if (this.newReportPack && this.selectedTemplate.reportDateMandatory && (this.ingestionModel.reportingperiod == null || this.ingestionModel.reportingperiod == undefined)) {
            this.alertService.error('Please select a reporting date!');
            return;
        }
        if (this.newReportPack && this.newReportPackSequence && (this.ingestionModel.reportPackSequenceName == null || this.ingestionModel.reportPackSequenceName == '')) {
            this.alertService.error('Report Pack Sequence Name is a mandatory field, when new report pack sequence creation is requested.');
            return;
        }
        if (!this.newReportPack && (this.ingestionModel.changereason === null || this.ingestionModel.changereason == undefined || this.ingestionModel.changereason === '')) {
            this.alertService.error('Change reason is a mandatory field!');
            return;
        }
        if (this.selectedTemplate.showFileUpload && (this.ingestionFile === null || this.showFileDetail === false)) {
            this.alertService.error('Ingestion file missing!');
            return;
        }
        if (this.selectedTemplate.showFileUpload && this.ingestionFile && this.ingestionModel && this.ingestionModel['fileName']) {
            const splitFilename = this.ingestionModel['fileName'].split('.');
            const ext = splitFilename.length >= 2 ? splitFilename[splitFilename.length - 1] : null;
            if (ext && ext != 'csv') {
                this.alertService.error('The uploaded file is not valid. Please upload a csv file to continue!');
                return;
            }
        }
        if (!this.newReportPack && this.selectedReportPack != null && this.selectedTemplate != null && !this.selectedTemplate.showFileUpload && this.selectedReportPack.DataTypes.indexOf(this.selectedTemplate.tableId) > -1) {
            this.alertService.error('The selected report pack already contains data for ' + this.selectedTemplate.name + '. Please edit the existing form for changing data!');
            return;
        }
        if (!this.newReportPack && this.selectedReportPack != null && this.selectedTemplate != null && this.selectedTemplate.showFileUpload && this.selectedReportPack.DataTypes.indexOf(this.selectedTemplate.tableId) > -1) {
            this.alertService.error('The selected report pack already contains a load in draft status for ' + this.selectedTemplate.name + '. Please submit the existing draft load or abandon the previous draft load using the \'Abandon Previous Draft Load\' button below, before a new upload.');
            return;
        }
        this.ingestionModel['reportsToPrepare'] = this.selectedReports;

        if (!this.selectedTemplate.showFileUpload) {
            this.ingestionModel['fileName'] = this.selectedTemplate.name;
            this.dataIngestionService.ingestionLoadData = this.ingestionModel;
            this.router.navigate(['/app/data-ingestion/ingestion-form']);
        }
        else {
            var formData = new FormData();
            formData.append('files', this.ingestionFile);
            formData.append('ingestionModel', JSON.stringify(this.ingestionModel));
            this.loading = true;

            this.dataIngestionService.startIngestion(formData).then((data: any) => {
                this.clear();
                this.loading = false;
                this.backToIngestion();
            }).catch(() => { this.loading = false; });
        }
    }

    clear() {
        this.initializeIngestionModel();
        this.selectedReports = '';
        this.ingestionFile = null;
        this.showFileDetail = false;
    }

    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    backToIngestion() {
        this.dataIngestionService.ingestionLoadData = null;
        this.router.navigate(['/app/data-ingestion']);
    }

    getTableIdsForTemplateGroup(templateGroupId: number) {
        var templateGroupDataTypes = [];
        this.completeTemplateList.filter(x => x.templateGroupId == templateGroupId).forEach(el => {
            templateGroupDataTypes.push(el.tableId);
        });
        return templateGroupDataTypes;
    }

    onAbandonPreviousLoad() {
        this.loading = true;
        this.dataIngestionService.abandonDraftLoad(this.ingestionModel.reportpackid, this.ingestionModel.tableId).then((data: any) => {
            this.selectedReportPack.DataTypes = this.selectedReportPack.DataTypes.filter(x => x != this.ingestionModel.tableId);
            this.loading = false;
        }).catch(() => { this.loading = false; });
    }
}